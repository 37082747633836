"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
// tslint:disable-next-line:no-var-requires
var styles = require('./style.css');
exports.About = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.hero, style: { backgroundImage: "url(" + props.staticSrcPath + "/assets/images/backgrounds/connect_hero.png)" } },
            React.createElement("div", null,
                React.createElement("h2", null, "About ayvri"),
                React.createElement("h3", null, "We take the data from your outdoor adventures and turn it into a next generation media experience"))),
        React.createElement("div", { className: styles.inner },
            React.createElement("div", { className: styles.content },
                React.createElement("p", null, "We regret to inform you that we can no longer support the consumer facing portion of Ayvri and will be shutting down in the coming months."),
                React.createElement("p", null, "Ayvri was originally created as Doarama in 2013 by Chris Cooper, with the support of NICTA, Australia\u2019s ICT Research Centre, and was supported by the Australian government through early development until 2017 when it was spun out as an independent company and rebranded as Ayvri."),
                React.createElement("p", null, "The site never would have existed without the work and vision of Chris Cooper, and the monetary support of the Australian government. I thought we had the opportunity to create a viable business from this incredible technology, and with a great team. I\u2019m sorry to say I was wrong. We were never able to find a business model which justified the amount of investment."),
                React.createElement("p", null, "Though we were able to maintain the public facing consumer operations of Ayvri over the years, the majority of our revenue came from our business partners."),
                React.createElement("p", null, "Over the last few years we\u2019ve entered into acquisition talks multiple times, and the messaging was clear each time, though acquiring parties appreciated the technology we had built, they have put no value on the community of users."),
                React.createElement("p", null, "Unfortunately, as the community continues to grow, and the cost of supporting the site continues to increase, we are seeing a drop in revenue from business partners, meaning we can no longer support costs involved in providing this service to the community."),
                React.createElement("h3", null, "We will be shutting down the consumer facing portion of Ayvri over the coming months based on the following schedule."),
                React.createElement("ul", null,
                    React.createElement("li", null, "September, 5th, Pro accounts will no longer be charged, upgrades to Pro accounts will no longer be available."),
                    React.createElement("li", null, "October, 1st - track upload and new scene creation will be removed"),
                    React.createElement("li", null, "October 31st - Access to scenes will be removed and all consumer data will be removed from the platform.")),
                React.createElement("h3", null, "Can I download my data or scenes?"),
                React.createElement("p", null, "Ayvri has never provided the ability to download data or scenes. 3D scenes cannot be downloaded to be viewed without map data, and we do not have licensing rights to download data to be used offline. We also do not have rights to mass export of 3D scenes to video."),
                React.createElement("p", null, "We know this may come as a surprise, and we\u2019re sorry we can no longer support the enjoyment of your activities."),
                React.createElement("p", null, "Many amazing people have put a lot of effort into making Ayvri the best 3D visualization tool on the web. I hope you\u2019ll join me in thanking them for their efforts."),
                React.createElement("div", null,
                    React.createElement("h1", null, "The team"),
                    React.createElement("div", { className: styles.steps + ' ' + styles.stepsTeam },
                        React.createElement("div", { className: styles.teamMember },
                            React.createElement("img", { src: props.staticSrcPath + "/assets/images/photos/pete.png" }),
                            React.createElement("h3", null, "Pete Field"),
                            React.createElement("h4", null, "Founder & CEO"),
                            React.createElement("div", null,
                                "Pete Field founded ayvri from technology spun out from ",
                                React.createElement("a", { href: 'data61.csiro.au' }, "Data61/CSIRO"),
                                ". He has a background in software development, PR & marketing and product/project management. In 2008 Pete built the",
                                React.createElement("a", { href: 'https://techcrunch.com/2008/10/27/hearwhere-lets-you-discover-live-music-in-your-neighborhood/', target: 'new' }, "world\u2019s largest concert search engine"),
                                ". A skier and mountain biker from Whistler, Canada, Pete has ridden the toughest mountain bike races on his singlespeed bike, he\u2019s competed in Ironman Canada, the Rottnest Swim, Melbourne Marathon and much more."))))))));
};
