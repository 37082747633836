"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var footer_1 = require("../../clean-components/footer");
var header_1 = require("../../clean-components/header");
var env_1 = require("../../helpers/env");
require("../../pages/global.css");
var about_1 = require("./about");
var doarama_1 = require("./doarama");
var privacy_1 = require("./privacy");
var terms_1 = require("./terms");
// tslint:disable-next-line:no-var-requires
var style = require('./style.css');
exports.staticContent = function (state, action) {
    if (state === void 0) { state = { page: '' }; }
    switch (action.type) {
        case 'ADD_PAGE':
            return __assign(__assign({}, state), { page: action.page });
        case 'ADD_PRICING':
            return __assign(__assign({}, state), { pricing: action.pricing });
        case 'ADD_CURRENCY':
            return __assign(__assign({}, state), { currency: action.currency });
        default:
            return state;
    }
};
exports.mapStateToProps = function (state) {
    return {
        page: state.page,
    };
};
exports.StaticJSX = function (store, loginState, staticSrcPath) {
    return (React.createElement(react_redux_1.Provider, { store: store },
        React.createElement("div", null,
            React.createElement(header_1.Header, { staticSrcPath: env_1.env().staticSrcPath(), loginState: loginState }),
            React.createElement("div", { className: style.static }, (function () {
                switch (store.getState().content.page.toLowerCase()) {
                    case 'about':
                        return React.createElement(about_1.About, { staticSrcPath: staticSrcPath });
                    case 'privacy':
                        return React.createElement(privacy_1.Privacy, null);
                    case 'terms':
                        return React.createElement(terms_1.Terms, null);
                    case 'doarama':
                        return React.createElement(doarama_1.DoaramaRedirect, { staticSrcPath: staticSrcPath });
                    default:
                        return React.createElement("div", null, "Page not found");
                }
            })()),
            React.createElement(footer_1.Footer, null))));
};
