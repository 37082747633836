"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
// tslint:disable-next-line:no-var-requires
var styles = require('./style.css');
exports.Privacy = function () {
    return (React.createElement("div", { className: styles.wrap },
        React.createElement("h3", null, "Privacy Policy"),
        React.createElement("pre", null, "In this Privacy Policy, Ayvri refers to Ayvri PTY LTD (ABN 53 620 853 296). \"We\", \"Us\" and \"Our\" refer collectively to \"Ayvri PTY LTD\" We are committed to protecting your privacy in accordance with the Australian Privacy Principles contained in the Privacy Act 1988 (Cth) (\"Privacy Act\"). Our Privacy Policy provides you with information about how \"personal information\" may be collected, accessed, used, stored, disclosed or otherwise handled by Ayvri. In some cases a Related Company may also provide you with additional information about how it handles personal information. Please refer to the Related Company websites listed above for further information."),
        React.createElement("h3", null, "1. What is \"personal information\"?"),
        React.createElement("pre", null, "\"Personal information\" is defined in the Privacy Act to mean any \"information or an opinion about an identified individual, or an individual who is reasonably identifiable\": - whether the information is true or not, and - whether the information or opinion is recorded in a material form or not. What constitutes personal information will vary, depending on whether an individual can be identified or is reasonably identifiable in the particular circumstances. Information that includes a person's first name, mailing address, telephone number, title or e-mail address may be personal information, for example. In this Privacy Policy we also use the term \"sensitive information\". Sensitive information includes personal information relating to health, as well other kinds of personal information to which higher standards apply under the Privacy Act."),
        React.createElement("h3", null, "2. Purposes for which we may collect and use personal information"),
        React.createElement("pre", null, "We collect personal information when reasonably necessary for one or more of our functions or activities. Purposes for which we collect, hold, disclose and otherwise use personal information include, but are not limited to, the following: - for research purposes, - to provide information, goods or services to you, - to promote and market Ayvri, - to provide statistical information (not including personal information) to government and other organisations, - to assess, receive, manage or terminate receipt of goods or services from you or the organisation which employs you, - to assess, manage and terminate scholarships and sponsorships (including applications and proposals), - to assess, manage and terminate joint ventures and collaborative projects, - to administer and improve the performance of our websites, - to support due diligence investigations, such as by a prospective purchaser of one of our Related Companies, and - as required by law. Our practices in relation to electronic mailing lists We do not send \"commercial electronic messages\" by email, SMS or other means unless the relevant electronic account holder consents or we are otherwise permitted to send them under the Spam Act 2003 (Cth). We include certain information about ourselves and an unsubscribe facility in any commercial electronic messages that we send. If you provide your email address to us via one of our websites (for instance, by sending an electronic message or by completing a web form), we will only use your email address for the purposes for which you provided it or agreed it may be used. Your email address will not be subscribed to a mailing list without your consent. If you subscribe to one of our mailing lists, you will be sent a confirmation message before your email address is added to that mailing list."),
        React.createElement("h3", null, "3. How we collect personal information"),
        React.createElement("pre", null, "If we collect personal information about you we will take reasonable steps to notify you or to ensure your awareness of at least the following: - ur identity and contact details, - the facts and circumstances of collection, - whether collection is required or authorised by law, - the purposes of collection, - the consequences if personal information is not collected, - ur usual disclosures of personal information of the kind collected, - information about this Privacy Policy, and - whether we are likely to disclose personal information about you to overseas recipients, and if practicable, the countries where they are located. We may collect personal information about you in a number of ways, including: - directly from you, such as when you provide information by phone or in a form, or when you submit your personal details through our websites; - from third parties, such our partners and Ayvri's members, credit reporting agencies or your representatives; or - from publicly available sources of information."),
        React.createElement("h4", null, "Sensitive information"),
        React.createElement("pre", null, "We will collect sensitive information only with the consent of the individuals concerned, subject to limited exceptions permitted by the Privacy Act. For example, if it is impracticable to obtain consent, we may collect and use health information for research purposes relevant to public health or public safety in accordance with the guidelines approved under section 95A of the Privacy Act. The guidelines require (among other things) obtaining an ethics approval from a human research ethics committee that is constituted and functioning in accordance with the National Statement of Ethical Conduct in Research Involving Humans."),
        React.createElement("h4", null, "Anonymity and pseudonymity"),
        React.createElement("pre", null, "You can interact with us anonymously or by using a pseudonym when it is lawful and practicable to do so. For example, you may be able to provide comments or make certain inquiries anonymously or by using a pseudonym. However, if you choose to remain anonymous or use a pseudonym, it may be impracticable for us to respond to some inquiries (e.g. investigate a complaint) without knowing your identity. Where you interact with us via one of our websites, we may automatically collect various connection parameters, such as your IP address and details about your internet service provider, in order to administer and improve the performance of the website. If the website uses \"cookies\" you may choose to configure your web browser to access our websites without accepting the cookies, but it may reduce your user experience and require you re-enter certain information from time to time."),
        React.createElement("h3", null, "4. How we hold personal information"),
        React.createElement("pre", null, "We will take reasonable steps to protect any personal information that we hold from misuse, loss, unauthorised access, modification or disclosure. We will also take reasonable steps to destroy or permanently de-identify personal information that is no longer needed for the purpose for which the information was collected. Your personal information may be stored in hardcopy documents, as electronic data, in our internal software or systems, or using a third party under an outsourced arrangement for data storage or backup."),
        React.createElement("h3", null, "Our practices in relation to emails"),
        React.createElement("pre", null, "Ayvri's stores email messages. The details captured are date, sender, receiver, subject, size of email message, and group that the sender belongs to. This information may be \"de-identified\" (so that it is no longer personal information) and then used in its de-identified form for internal research purposes and aesthetic purposes approved through applicable processes. Further, if an investigation is necessary, a law enforcement agency or other government agency may inspect the above information for the purposes of the investigation. We do not otherwise use or disclose the above information except in accordance with applicable laws, including the Privacy Act."),
        React.createElement("h3", null, "5. Disclosure of personal information"),
        React.createElement("pre", null, "Ayvri may disclose your personal information for the primary purpose for which it was collected, for a related purpose as you would reasonably expect, with your consent, or otherwise as required or authorised by law. For example, we may disclose your personal information to: - ur professional advisors including our accountants, auditors and lawyers, - your authorised representatives or legal advisors (e.g. when requested by you to do so), - credit-reporting and fraud-checking agencies, - third parties to whom we have outsourced various business functions, - Government and regulatory authorities and other organisations, as required or authorised by law, - ur partners or other third parties who assist us in achieving the purpose for which the information was collected. In general, we are not likely to disclose your personal information to overseas recipients, although we may from time to time engage, under an outsourcing arrangement, the data storage or backup services of a cloud service provider that hosts (or may host) data outside of Australia. In some cases we may also expressly seek your consent to disclose your personal information to an overseas recipient, for the purpose of excluding any potential liability to you for acts or practices done by the overseas recipient in breach of applicable Australian Privacy Principles."),
        React.createElement("h3", null, "6. How to access and correct your personal information"),
        React.createElement("pre", null, "You have a right under the Privacy Act to request access to your personal information, and to request its correction. We will provide you with access to personal information that we hold about you, subject to any exceptions allowed by law. We may charge you for providing access, but the charge (if any) will not be excessive and we will never charge you for making a request for access. If you are aware that personal information which we hold about you is inaccurate, incomplete or out-of-date, please contact us and we will take reasonable steps to correct the information or, if necessary, to associate a statement that the personal information is inaccurate, out-of-date, incomplete, irrelevant or misleading, in such a way that will make the statement apparent to users of the personal information. We will also respond to each request within a reasonable period after it is made. If we do not provide access or make corrections as requested, then we will notify you of our reasons except to the extent that it would be unreasonable to do so."),
        React.createElement("h3", null, "7. Third-Party Accounts"),
        React.createElement("pre", null, "Ayvri allos you to sign up and login to the service with an account created by a thrid-party such as Facebook and Google. If you access the service through a third-party account, we will collect information you agree to make available to us from the third-party including profile image, email, and full name. You can generally control what information we receive from these sources using the privacy controls provided in your third-party account."),
        React.createElement("h3", null, "8. Service Providers"),
        React.createElement("pre", null, "We may share your information with third parties who provide services such as supporting, improving, promoting and securing the Services, processing payments, or managing accounts. These service providers only have access to the information necessary to perform these limited functions on our behalf and are required to protect and secure your information."),
        React.createElement("h3", null, "Deleting Account"),
        React.createElement("pre", null, "You may terminate your account and delete all your data by sending a request to delete your account to hello@ayvri.com. The email must be sent from the email address associated with the account requesting deletion."),
        React.createElement("pre", null, "We may change or modify this Privacy Policy from time to time. We encourage you to check our websites regularly for updates.")));
};
