"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var videoHero_1 = require("../../components/videoHero");
// tslint:disable-next-line:no-var-requires
var styles = require('./style.css');
exports.DoaramaRedirect = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(videoHero_1.Hero, { backgroundImg: props.staticSrcPath + "/assets/images/backgrounds/Ground_2.png", videoId: 'LGpSyerGjmU' },
            React.createElement("div", { className: styles.tagline },
                React.createElement("img", { src: props.staticSrcPath + "/assets/images/logos/doarama-mark-light.svg" }),
                " has a new home.",
                React.createElement("br", null),
                "Welcome to ayvri")),
        React.createElement("div", { className: styles.content },
            React.createElement("h1", null, "Doarama has moved to Ayvri.com"),
            React.createElement("p", null, "All the great stuff you loved about Doarama is now on ayvri.\n          Your browser should redirect automatically, or",
                React.createElement("a", { href: '/' }, "click here")))));
};
