"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("redux");
var redux_thunk_1 = require("redux-thunk");
var env_1 = require("../../helpers/env");
var rendering_1 = require("../../pages/rendering");
var login_1 = require("../../reducers/login");
var user_1 = require("../../reducers/user");
var static_1 = require("./static");
var preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;
delete preloadedState.___;
var store = redux_1.createStore(redux_1.combineReducers({ user: user_1.user, loginUrl: login_1.loginUrlReducer, content: static_1.staticContent }), preloadedState, redux_1.applyMiddleware(redux_thunk_1.default));
var loginState = preloadedState.loginState;
rendering_1.hydrateToRoot(static_1.StaticJSX(store, loginState, env_1.env().staticSrcPath()));
if (store.getState().content.page.toLowerCase() === 'doarama') {
    setTimeout((window.location.href = '/'), 5000);
}
